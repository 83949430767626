import { Editor } from '@tinymce/tinymce-react';
import { isEmpty, isNull, isUndefined } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

const CustomEditor = React.forwardRef(function CustomEditorRef(
  { onInputChange, onInputChangeText = null, label, name, labelOptions = { useLabel: true, disabled: false }, isRequired = false },
  ref
) {
  const apiKey = 'w322wpfxdmk8yxtzlgbxmfbaz2bzzuqxwuykm820kwpowf61';
  const { t: translate } = useTranslation();
  const {
    register,
    watch,
    getValues,
    formState: { errors }
  } = useFormContext();

  register(name, { required: isRequired });
  const fieldHasError = !isUndefined(errors[`${name}`]) && isRequired && isEmpty(watch(name));
  const value = getValues(name);

  return (
    <FormGroup>
      {labelOptions.useLabel && <Label className="col-form-label">{translate(label)}</Label>}
      <Editor
        value={value}
        apiKey={apiKey}
        disabled={labelOptions.disabled}
        onEditorChange={(newValue, editor) => {
          const content = editor.getContent();
          const contentText = editor.getContent({ format: 'text' });
          isNull(onInputChange) ? null : onInputChange(content);
          isNull(onInputChangeText) ? null : onInputChangeText(contentText);
        }}
        init={{
          height: 500,
          menubar: true,
          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
          toolbar: `undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help`,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          file_picker_callback: (callback, value, meta) => {}
        }}
      />
      <span
        style={{
          fontSize: 12,
          color: '#dc3545',
          display: fieldHasError ? 'block' : 'none'
        }}
      >{`${translate(label)} ${errors[`${name}`]?.message || 'is required'}`}</span>
    </FormGroup>
  );
});

export default CustomEditor;
