import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Brand, MAX, MIN, Price } from '../../../constant';
import { fetchMarketplaceProductOptions } from '../../../redux/product/action';
import { capitalize, groupBy, isEmpty, startCase, has } from 'lodash';
import { Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { MIN_PRICE_CANNOT_BE_GREATER_THAN_MAX_PRICE } from './error-constants';

const MarketplaceFilter = ({ pageSelector, setPageSelector }) => {
  const { t: translate } = useTranslation();
  const [productOptions, setProductOptions] = useState({});
  const [priceArray, setPriceArray] = useState([0, 0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarketplaceProductOptions(processResult));
  }, []);

  const processResult = (data) => {
    if (isEmpty(data)) return;

    const brand = data.brand;
    const attributes = groupBy(data.attributes, 'key');

    setProductOptions({ brand, attributes });
  };

  const convertName = (name) => {
    if (name.length < 3) return name.toUpperCase();

    return startCase(name);
  };

  const handleOptionsSelector = (e, parent) => {
    const target = e.target;
    let temp = pageSelector;

    if (target.checked) {
      if (!has(temp, parent)) {
        temp = { ...temp, [parent]: [target.value] };
      } else {
        temp = { ...temp, [parent]: [...temp[parent], target.value] };
      }
    } else {
      const filteredValue = temp[parent].filter((data) => data !== target.value);
      if (isEmpty(filteredValue)) {
        delete temp[parent];
      } else {
        temp = { ...temp, [parent]: filteredValue };
      }
    }

    setPageSelector({ ...pageSelector, ...temp });
  };

  const handlePrice = (number, range) => {
    const temp = priceArray;
    if (range === 'min') {
      temp[0] = isEmpty(number) ? 0 : Number(number);
    }

    if (range === 'max') {
      temp[1] = Number(number);
    }

    setPriceArray(temp);
  };

  const handlePriceOnBlur = () => {
    if (priceArray[1] > 0 && priceArray[0] > priceArray[1]) {
      return toast.error(translate(MIN_PRICE_CANNOT_BE_GREATER_THAN_MAX_PRICE));
    }

    if (priceArray[1] > 0) {
      setPageSelector({ ...pageSelector, ['price']: priceArray });
    }
  };

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">{Brand}</h6>
        <div className="checkbox-animated mt-0">
          {productOptions.brand &&
            productOptions.brand.map(({ key, number }, index) => {
              return (
                <label className="d-block" key={index} onChange={(e) => handleOptionsSelector(e, 'brand')}>
                  <input className="checkbox_animated" value={key} id={index} type="checkbox" />
                  {`${capitalize(key)} (${number})`}
                </label>
              );
            })}
        </div>
      </div>
      {productOptions.attributes &&
        Object.entries(productOptions.attributes).map(([key, value], indexKey) => {
          return (
            <div className="product-filter slider-product" key={indexKey}>
              <h6 className="f-w-600">{convertName(key)}</h6>
              <div className="color-selector">
                <ul>
                  {value.map(({ value: val, number }, i) => {
                    return (
                      <label className="d-block" key={i} onChange={(e) => handleOptionsSelector(e, key)}>
                        <input className="checkbox_animated" value={val} id={i} type="checkbox" />
                        {`${val} (${number})`}
                      </label>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}

      <div>
        <div className="product-filter pb-0">
          <h6 className="f-w-600">{Price}</h6>
          <Row>
            <Col>
              <Input className="form-control mr-2" type="text" placeholder={MIN} onChange={(e) => handlePrice(e.target.value, 'min')} onBlur={handlePriceOnBlur} />
            </Col>
            <Col>
              <Input className="form-control" type="text" placeholder={MAX} onChange={(e) => handlePrice(e.target.value, 'max')} onBlur={handlePriceOnBlur} />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default MarketplaceFilter;
