import { identity, isEmpty, isNil, isUndefined, pickBy } from 'lodash';
import { Month, Week, Year } from '../constant';

export const generateProductData = (data) => {
  const warranty = processWarranty(data.warranty);
  const { userId, ownerType, originCountry, region } = data.productInfo;
  const attributes = preProcessAttributes(data.attribute);
  const productImages = data.productImageData;
  const productMeta = !isNil(data.productMetaTag) && !isEmpty(data.productMetaTag) ? data.productMetaTag : null;

  const output = {
    productName: data.productName,
    productModel: data.productModel,
    costPrice: convertStringToNumber(data.costPrice),
    salePrice: convertStringToNumber(data.salePrice),
    quantity: convertStringToNumber(data.quantity),
    sku: data.sku,
    category: convertStringToNumber(data.category),
    subcategory: convertStringToNumber(data.subcategory),
    minicategory: convertStringToNumber(data.minicategory),
    brand: convertStringToNumber(data.brand),
    productAttributes: attributes,
    productDetails: {
      description: data.productDescription,
      manufactureYear: convertStringToNumber(data.manufactureYear),
      warranty,
      condition: data.productCondition
    },
    productOwner: {
      productOwnerId: userId,
      ownerType: ownerType,
      isMainOwner: true
    },
    productLocale: {
      originCountry: originCountry,
      region: region,
      mainLanguageCode: 'en'
    },
    priceVariation: data.priceVariation,
    productDiscount: data.discountData,
    productImages,
    productMeta,
    productVariation: data.productVariationData
  };

  // remove undefined values from the object
  return pickBy(output, identity);
};
export const generateProductDataForUpdate = (data) => {
  const warranty = processWarranty(data.warranty);
  const attributes = preProcessAttributes(data.attribute);
  const productImages = data.productImageData;
  const productMeta = !isNil(data.productMetaTag) && !isEmpty(data.productMetaTag) ? data.productMetaTag : null;

  const output = {
    productName: data.productName,
    productModel: data.productModel,
    salePrice: convertStringToNumber(data.salePrice),
    quantity: convertStringToNumber(data.quantity),
    subcategory: convertStringToNumber(data.subcategory),
    minicategory: convertStringToNumber(data.minicategory),
    brand: convertStringToNumber(data.brand),
    productAttributes: attributes,
    productDetails: {
      description: data.productDescription,
      manufactureYear: convertStringToNumber(data.manufactureYear),
      warranty,
      condition: data.productCondition
    },
    priceVariation: data.priceVariation,
    productDiscount: data.discountData,
    productImages,
    productMeta,
    productVariation: data.productVariationData
  };

  // remove undefined values from the object
  return pickBy(output, identity);
};

export const transformProductDataToFormData = (data) => {
  const discount = !isNil(data.productDiscount)
    ? {
        discountValue: data.productDiscount.discountValue,
        startDate: data.productDiscount.start,
        endDate: data.productDiscount.end
      }
    : null;

  return {
    productName: data.productName,
    productModel: data.productModel,
    costPrice: data.costPrice,
    salePrice: data.salePrice,
    quantity: data.quantity,
    category: data.category.categoryId,
    subcategory: data.subcategory.subcategoryId,
    minicategory: data?.minicategory?.minicategoryId,
    brand: data.brand.brandId,
    warranty: transformWarranty(data.productDetails.warranty),
    manufactureYear: data.productDetails.manufactureYear,
    productCondition: data.productDetails.condition,
    productDescription: data.productDetails.description,
    imagesFromDB: data.productImages,
    discountFromDB: discount,
    productVariationFromDB: data.productVariation,
    attributesFromDB: data.productAttributes
  };
};

const convertStringToNumber = (data) => (!isUndefined(data) ? Number(data) : data);

const processWarranty = (data) => {
  const { type, value } = data;

  switch (type) {
    case Year:
      return `${value} Year(s)`;
    case Month:
      return `${value} Month(s)`;
    case Week:
      return `${value} Week(s)`;
    default:
      return value;
  }
};

const transformWarranty = (data) => {
  if (isEmpty(data)) return { type: '', value: '' };

  const [value, rawType] = data.split(' ');
  const cleanedType = rawType.replace('(s)', '');
  return { type: cleanedType, value };
};

export const preProcessAttributes = (data) =>
  Object.entries(data)
    .map(([key, value]) => {
      let dataType = 'string';
      let transformed = value;
      if (typeof value === 'object') {
        transformed = value.join();

        if (Array.isArray(value)) {
          dataType = 'array';
        } else {
          dataType = typeof value;
        }
      }
      return { key, value: transformed, dataType };
    })
    .filter(({ value }) => !isUndefined(value));

export const preProcessPriceVariation = (data) =>
  data.map((res) => ({
    countryISO: res.to,
    baseCurrency: res.from,
    currencySymbol: res.symbol_native,
    price: res.result
  }));

export const preProcessProductVariation = (res, locationData) => {
  const { currency, currencyIso, country } = locationData;
  return {
    type: res.type,
    value: res.value,
    description: res.description,
    productVariationImages: res.images,
    price: {
      countryISO: country.isoCode,
      baseCurrency: currencyIso,
      currencySymbol: isEmpty(currency.symbol_native) ? currency.symbol : currency.symbol_native,
      price: convertStringToNumber(res.price)
    }
  };
};

export const quantityHelper = (quantity) => {
  if (quantity >= 10) {
    return <div className="font-success">{`In Stock (${quantity})`}</div>;
  }

  if (quantity > 0 && quantity < 10) {
    return <div className="font-info">{`Low Stock (${quantity})`}</div>;
  }

  if (quantity === 0) {
    return <div className="font-danger">{`Out of Stock (${quantity})`}</div>;
  }
};
