/* CUSTOMIZER */
export const WATCH_CUSTOMIZER = 'WATCH_CUSTOMIZER';
export const ADD_COSTOMIZER = 'ADD_COSTOMIZER';
export const ADD_LAYOUT = 'ADD_LAYOUT';
export const ADD_SIDEBAR_TYPES = 'ADD_SIDEBAR_TYPES';
export const ADD_SIDEBAR_SETTINGS = 'ADD_SIDEBAR_SETTINGS';
export const ADD_COLOR = 'ADD_COLOR';
export const ADD_MIX_BACKGROUND_LAYOUT = 'ADD_MIX_BACKGROUND_LAYOUT';
export const ROUTER_ANIMATION = 'ROUTER_ANIMATION';

/** APP */
export const SET_ERROR_RESPONSE = 'SET_ERROR_RESPONSE';
export const SET_SUCCESS_RESPONSE = 'SET_SUCCESS_RESPONSE';
export const SET_LOADING = 'SET_LOADING';
export const SET_CLIENT_IP = 'SET_CLIENT_IP';
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA';
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const SET_ALL_COUNTRIES = 'SET_ALL_COUNTRIES';
export const GET_ALL_CURRENCIES_ISO = 'GET_ALL_CURRENCIES_ISO';
export const SET_ALL_CURRENCIES_ISO = 'SET_ALL_CURRENCIES_ISO';

/* REQUESTS */
export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_ENDED = 'REQUEST_ENDED';
export const REQUEST_ERROR = 'REQUEST_ERROR';

/* USER */
export const REDIRECT_TO_LOGIN_SCREEN = 'REDIRECT_TO_LOGIN_SCREEN';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_AUTHENTICATION_STATUS = 'SET_AUTHENTICATION_STATUS';
export const GET_LOGGED_IN_STATUS = 'GET_LOGGED_IN_STATUS';
export const SET_LOGGED_IN_STATUS = 'SET_LOGGED_IN_STATUS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const GET_USER_DATA = 'GET_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const RESET_USER_DATA = 'RESET_USER_DATA';

/* CATEGORY */
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const SET_CATEGORY = 'SET_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_SUBCATEGORY_FOR_CATEGORY = 'GET_SUBCATEGORY_FOR_CATEGORY';
export const SET_SUBCATEGORY_FOR_CATEGORY = 'SET_SUBCATEGORY_FOR_CATEGORY';
export const RESET_SUBCATEGORY_FOR_CATEGORY = 'RESET_SUBCATEGORY_FOR_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

/* SUBCATEGORY */
export const SAVE_SUBCATEGORY = 'SAVE_SUBCATEGORY';
export const SET_SUBCATEGORY = 'SET_SUBCATEGORY';
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const GET_MINICATEGORY_FOR_SUBCATEGORY = 'GET_MINICATEGORY_FOR_CATEGORY';
export const SET_MINICATEGORY_FOR_SUBCATEGORY = 'SET_MINICATEGORY_FOR_CATEGORY';
export const RESET_MINICATEGORY_FOR_SUBCATEGORY = 'RESET_MINICATEGORY_FOR_CATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';

/* MINICATEGORY */
export const SAVE_MINICATEGORY = 'SAVE_MINICATEGORY';
export const SET_MINICATEGORY = 'SET_MINICATEGORY';
export const GET_MINICATEGORY = 'GET_MINICATEGORY';
export const DELETE_MINICATEGORY = 'DELETE_MINICATEGORY';

/* BRANDS */
export const SAVE_BRANDS = 'SAVE_BRANDS';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_DETAILS = 'GET_BRANDS_DETAILS';
export const SET_BRANDS = 'SET_BRANDS';
export const DELETE_BRANDS = 'DELETE_BRANDS';

/* PRODUCTS */
export const GET_PRODUCT_IDENTIFIER_NUMBER = 'GET_PRODUCT_IDENTIFIER_NUMBER';
export const GET_PRODUCT_STATIC_DATA = 'GET_PRODUCT_STATIC_DATA';
export const SET_PRODUCT_STATIC_DATA = 'SET_PRODUCT_STATIC_DATA';
export const CONVERT_GROUP_CURRENCIES = 'CONVERT_GROUP_CURRENCIES';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_MARKETPLACE_PRODUCTS = 'GET_MARKETPLACE_PRODUCTS';
export const GET_MARKETPLACE_PRODUCTS_OPTIONS = 'GET_MARKETPLACE_PRODUCTS_OPTIONS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SET_CURRENT_SELECTED_PRODUCT = 'SET_CURRENT_SELECTED_PRODUCT';
export const RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST';
export const ADD_PRODUCT_TO_VENDOR_STORE = 'ADD_PRODUCT_TO_VENDOR_STORE';

/* SELLERS */
export const GET_SELLER_INFO = 'GET_SELLER_INFO';
export const SET_SELLER_INFO = 'SET_SELLER_INFO';
export const UPDATE_SELLER_INFO = 'UPDATE_SELLER_INFO';

/* FILE UPLOAD */
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FILE = 'DELETE_FILE';
