import React, { useContext } from 'react';
import ProductImages from './attributes-layout/ProductImages';
import PriceVariation from './attributes-layout/PriceVariation';
import ProductVariation from './attributes-layout/ProductVariation';
import Discounts from './attributes-layout/Discounts';
import { AddProductContext } from '../../../../helper/contextHelper';

export default function Middle() {
  const { cannotEditProduct } = useContext(AddProductContext);

  return (
    <>
      <ProductImages />
      <PriceVariation />
      {!cannotEditProduct && <ProductVariation />}
      <Discounts />
    </>
  );
}
