import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Button, Media, Input } from 'reactstrap';
import { generatePath, useNavigate } from 'react-router';
import { Active, Delete, Disabled, Edit } from '../../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ADD_PRODUCT, ALL_PRODUCTS } from './constants';
import CustomDataTables from '../../../components/table/CustomDataTables';
import { deleteProduct, getProducts, setCurrentSelectedProduct } from '../../../redux/product/action';
import { quantityHelper } from '../../../helper/productHelper';
import { debounce, isEmpty } from 'lodash';
import { getAllCountries } from '../../../redux/app/action';
import internalRoutes from '../../../route/internalRoutes';
import { getAllCategory } from '../../../redux/category/action';

const ListProducts = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const { allCountries } = useSelector((state) => state.app);
  const { allCategory } = useSelector((state) => state.categories);
  const { info } = useSelector((state) => state.seller);
  const [searchValue, setSearchValue] = useState('');
  const [pageOptions, setPageOptions] = useState({});
  const key = 'productTableOption';

  useEffect(() => {
    if (isEmpty(allCountries)) {
      dispatch(getAllCountries());
    }

    if (isEmpty(allCategory)) {
      dispatch(getAllCategory());
    }

    getPageOptionFromStorage();
  }, []);

  useEffect(() => {
    // refractor me please, I should not be checking if the seller info is available
    // check getUserData() in redux
    if (!isEmpty(pageOptions) && !isEmpty(info)) {
      dispatch(getProducts(pageOptions));
    }
    setToLocalStorage(pageOptions);
  }, [pageOptions, info]);

  const setToLocalStorage = (options) => {
    localStorage.setItem(key, JSON.stringify(options));
  };

  const getPageOptionFromStorage = () => {
    const items = localStorage.getItem(key);
    const value = JSON.parse(items);

    if (isEmpty(value)) {
      return setPageOptions({
        pageNumber: 1,
        itemNumber: 10
      });
    }

    setPageOptions(JSON.parse(items));
  };

  const navigateToAddProductPage = () => {
    if (info.sellerType.type === 'VENDOR') {
      return navigate('/marketplace');
    }

    navigate('/products/new/');
  };
  const transformProductPrice = (product) => {
    const {
      salePrice,
      productLocale: { originCountry }
    } = product;
    const country = allCountries.find(({ name }) => name === originCountry);
    const currencySymbol = country.currency_symbol;

    return <div>{`${currencySymbol} ${salePrice}`}</div>;
  };

  const handleProductEdit = (productId) => {
    const product = products.data.find((data) => data.productId === productId);
    dispatch(setCurrentSelectedProduct(product));
    navigate(generatePath(internalRoutes.GOTO_EDIT_PRODUCT, { productId }));
  };

  const handleRowPageChange = (items, page) => {
    let options = { ...pageOptions, pageNumber: page, itemNumber: items };

    if (!isEmpty(searchValue)) {
      options = { ...options, searchValue };
    }

    setPageOptions(options);
  };

  const handlePageNumber = (page) => {
    let options = { ...pageOptions, pageNumber: page };

    if (!isEmpty(searchValue)) {
      options = { ...options, searchValue };
    }

    setPageOptions(options);
  };

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProduct(productId));
  };

  const handleSorting = (column, direction) => {
    // the third params is rows
    let options = { ...pageOptions, sortColumn: column.key, sortDirection: direction };
    setPageOptions(options);
  };

  const searchProductFromServer = useCallback((options, searchText) => dispatch(getProducts({ ...options, searchValue: searchText })));
  const searchDebounced = useMemo(
    () =>
      debounce(function (value) {
        searchProductFromServer(pageOptions, value);
      }, 1000),
    [searchProductFromServer]
  );

  const searchProductHandler = (value) => {
    if (isEmpty(value)) {
      setPageOptions({ ...pageOptions, searchValue: '' });
      dispatch(getProducts(pageOptions));
    }

    if (value.length < 2) return;

    setSearchValue(value);
    searchDebounced(value);
    setPageOptions({ ...pageOptions, searchValue: value });
  };

  const columns = [
    {
      name: 'Image',
      cell: (row) => <Media style={{ maxWidth: '50px', maxHeight: '50px' }} className="my-2" src={row?.productImages[0]?.fileLink} />
    },
    {
      name: 'Product Name',
      key: 'productName',
      selector: (row) => row.productName,
      sortable: true
    },
    {
      name: 'Category',
      key: 'category',
      selector: (row) => row.category.name,
      sortable: true
    },
    {
      name: 'Brand',
      key: 'brand',
      selector: (row) => row.brand.brandName,
      sortable: true
    },
    {
      name: 'Quantity',
      key: 'quantity',
      selector: (row) => row.quantity,
      sortable: true,
      cell: (row) => quantityHelper(row.quantity)
    },
    {
      name: 'Price',
      key: 'price',
      selector: (row) => row.salePrice,
      sortable: true,
      cell: (row) => transformProductPrice(row)
    },
    {
      name: 'Status',
      cell: (row) => {
        return (
          <Button color={row.isActive ? 'primary' : 'danger'} size="xs">
            {row.isActive ? Active : Disabled}
          </Button>
        );
      }
    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <Button color="success" style={{ marginRight: 6 }} size="xs" onClick={() => handleProductEdit(row.productId)}>
            {translate(Edit)}
          </Button>
          {row.productOwner.isMainOwner && (
            <Button color="danger" size="xs" onClick={() => handleDeleteProduct(row.productId)}>
              {translate(Delete)}
            </Button>
          )}
        </div>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb parent="ecommerce" title={translate(ALL_PRODUCTS)} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Col className="d-flex flex-row justify-content-between">
                  <Button className="col-md-3" style={{ marginBottom: '10px' }} color="primary" onClick={navigateToAddProductPage}>
                    {translate(ADD_PRODUCT)}
                  </Button>
                  <Col md="6">
                    <Input type="search" className="form-control" placeholder="Search products" onChange={(e) => searchProductHandler(e.target.value)} />
                  </Col>
                </Col>
                {!isEmpty(pageOptions) && (
                  <CustomDataTables
                    columns={columns}
                    data={products.data}
                    useServerPagination={true}
                    totalRows={products.totalItems}
                    onRowClick={(row) => {
                      handleProductEdit(row.productId);
                    }}
                    onChangeRowsPage={handleRowPageChange}
                    changePage={handlePageNumber}
                    pageNumber={pageOptions.pageNumber}
                    itemNumber={pageOptions.itemNumber}
                    sortingFunction={handleSorting}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ListProducts;
