export const defaultQueryParams = {
  page: 1,
  size: 24,
  search: '',
  sort: '',
  s_order: ''
};

export const convertQueryObjectToString = (queryObject) =>
  Object.entries(queryObject)
    .reduce((prev, curr) => {
      if (!curr[1]) return prev;

      prev += `${curr[0]}=${curr[1]}&`;
      return prev;
    }, '')
    .slice(0, -1);
