import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'reactstrap';
import { isEmpty, isNil } from 'lodash';
import { Back, Save, Update } from '../../../../constant';
import Breadcrumb from '../../../../layout/breadcrumb';
import Left from './Left';
import Middle from './Middle';
import Right from './Right';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries } from '../../../../redux/app/action';
import { createProduct, getProductById, getProductStaticData, updateProduct } from '../../../../redux/product/action';
import { generateProductData, generateProductDataForUpdate, transformProductDataToFormData } from '../../../../helper/productHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { AddProductContext } from '../../../../helper/contextHelper';
import { ValidRightComponents } from '../constants';

const AddProduct = (props) => {
  const { edit } = props;
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const useFormObj = useForm({});
  const { allCountries, locationData } = useSelector((state) => state.app);
  const { userData } = useSelector((state) => state.auth);
  const { info: sellerInfo } = useSelector((state) => state.seller);
  const { staticData, currentSelectedProduct } = useSelector((state) => state.product);
  const { allCategory } = useSelector((state) => state.categories);
  const [categoryName, setCategoryName] = useState('');
  const [showRightComponent, setShowRightComponent] = useState(false);
  const isEditMode = !isNil(edit);
  const pageTitle = isEditMode ? 'Edit Product' : 'Add Product';
  const cannotEditProduct = isEditMode && !currentSelectedProduct.productOwner?.isMainOwner;

  useEffect(() => {
    if (isEmpty(allCountries)) {
      dispatch(getAllCountries());
    }

    if (isEmpty(staticData)) {
      dispatch(getProductStaticData());
    }

    if (isEditMode && isEmpty(currentSelectedProduct)) {
      dispatch(getProductById(productId));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(userData) && !isEmpty(sellerInfo) && !isEmpty(locationData)) {
      const { userId } = userData;
      const { country, city } = locationData;

      const value = {
        userId,
        ownerType: sellerInfo.sellerType.type,
        originCountry: country.name,
        region: city?.name
      };

      useFormObj.setValue('productInfo', value);
    }
  }, [userData, sellerInfo, locationData]);

  useEffect(() => {
    if (isEditMode && !isEmpty(currentSelectedProduct)) {
      const productData = transformProductDataToFormData(currentSelectedProduct);
      Object.entries(productData).forEach(([key, value]) => useFormObj.setValue(key, value));
    }
  }, [currentSelectedProduct]);

  useEffect(() => {
    const component = useFormObj.getValues('category');
    if (!isNil(component) || !isEmpty(component)) {
      const id = Number(component);
      const { name } = allCategory.find(({ categoryId }) => categoryId === id);
      const catName = name.toLowerCase();
      setCategoryName(catName);

      // show the category attributes
      const valid = Object.values(ValidRightComponents);
      setShowRightComponent(valid.includes(catName));
    }
  }, [useFormObj.watch('category')]);

  const navigateToProductList = () => navigate('/products/');

  const handleFormSubmit = (data) => {
    if (isEditMode) {
      const processedData = generateProductDataForUpdate(data);
      dispatch(updateProduct(productId, processedData, navigateToProductList, handleEndpointError));
    } else {
      const processedData = generateProductData(data);
      dispatch(createProduct(processedData, navigateToProductList, handleEndpointError));
    }
  };

  const handleFormError = () => {
    // console.log(e);
    // handle errors from the form
  };

  const handleEndpointError = () => {
    // handle errors from the endpoint 400 request
  };

  return (
    <Fragment>
      <Breadcrumb parent="Ecommerce" title={pageTitle} />
      <Container fluid={true}>
        <AddProductContext.Provider value={{ isEditMode, cannotEditProduct, categoryName, showRightComponent }}>
          <FormProvider {...useFormObj}>
            <Form className="theme-form mega-form" onSubmit={(e) => useFormObj.handleSubmit(handleFormSubmit, handleFormError)(e).catch(handleFormError)} noValidate>
              <Row>
                <Col>
                  <Left />
                </Col>
                <Col>
                  <Middle />
                </Col>
                {!cannotEditProduct && <Right />}
              </Row>
              <div className="d-flex flex-row justify-content-end p-10">
                <div>
                  <Button color="danger" onClick={navigateToProductList}>
                    {translate(Back)}
                  </Button>
                </div>
                <div style={{ marginLeft: 6 }}>
                  <Button type="submit" color="primary">
                    {isEditMode ? translate(Update) : translate(Save)}
                  </Button>
                </div>
              </div>
            </Form>
          </FormProvider>
        </AddProductContext.Provider>
      </Container>
    </Fragment>
  );
};

export default AddProduct;
