import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  Form,
  Input,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { Grid, List } from 'react-feather';
import { Link, useSearchParams } from 'react-router-dom';
import errorImg from '../../../assets/images/search-not-found.png';
import { Filters, LowestPrices, HighestPrices, NotFoundData, ProductDetails, ViewDetails, ProductSizeArray, Previous, Next, AddToStore, Recommended } from '../../../constant';
import { useTranslation } from 'react-i18next';
import { addProductToVendor, fetchMarketplaceProducts } from '../../../redux/product/action';
import decodeHtmlEntity from '../../../helper/htmlEntityDecode';
import { debounce, isEmpty, isNil, range } from 'lodash';
import { getAllCountries } from '../../../redux/app/action';
import MarketplaceFilter from './marketplaceFilter';
import { convertQueryObjectToString } from './constants';

const MarketPlace = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  let [queryParams, setQueryParams] = useSearchParams();
  const { allCountries } = useSelector((state) => state.app);
  const { info } = useSelector((state) => state.seller);
  const layoutColumns = 3;
  const symbol = '';
  const [open, setOpen] = useState(false);
  const [sidebaron, setSidebaron] = useState(true);
  const [singleProduct, setSingleProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [filterSidebar, setFilterSidebar] = useState(true);
  const [productOption, setProductOption] = useState({});
  const [currentSortValue, setCurrentSortValue] = useState(Recommended);
  const [pageSelector, setPageSelector] = useState({
    page: queryParams.get('page') ?? 1,
    size: queryParams.get('size') ?? 24,
    sort: queryParams.get('sort'),
    s_order: queryParams.get('s_order'),
    search: queryParams.get('search')
  });

  useEffect(() => {
    if (isEmpty(allCountries)) {
      dispatch(getAllCountries());
    }
  }, []);

  useEffect(() => {
    const { search } = pageSelector;
    let url = convertQueryObjectToString(pageSelector);
    setQueryParams(url);

    if (!isNil(search)) {
      return;
    }

    dispatch(fetchMarketplaceProducts(pageSelector, processProductResult));
  }, [pageSelector]);

  const processProductResult = (result) => {
    setProducts(result.data);
    delete result.data;
    setProductOption(result);
  };

  const transformProductPrice = (product) => {
    if (isEmpty(product)) return null;

    const {
      salePrice,
      productLocale: { originCountry }
    } = product;

    const country = allCountries.find(({ name }) => name === originCountry);
    return <div>{`${new Intl.NumberFormat(country.iso2, { style: 'currency', currency: country.currency }).format(salePrice)}`}</div>;
  };

  const handlePagination = (page = 1, size = 24) => {
    page = page < 1 ? 1 : page;
    const queryObject = { ...pageSelector, page, size };
    setPageSelector(queryObject);
  };

  const handleSorting = (e) => {
    const target = e.target;
    const currentIndex = target.selectedIndex;
    const value = target[currentIndex].dataset.value;
    const column = target[currentIndex].dataset.column;
    const currentValue = target.value;

    const isRecommended = currentValue === Recommended;
    const queryObject = { ...pageSelector, sort: isRecommended ? undefined : column, s_order: isRecommended ? undefined : value };

    setCurrentSortValue(currentValue);
    setPageSelector(queryObject);
  };

  const searchProductFromServer = useCallback((options, searchText) => dispatch(fetchMarketplaceProducts({ ...options, search: searchText }, processProductResult)));
  const searchDebounced = useMemo(
    () =>
      debounce(function (value) {
        searchProductFromServer(pageSelector, value);
      }, 1000),
    [searchProductFromServer]
  );

  const handleSearch = (value) => {
    let fetchOption = { ...pageSelector, page: 1, size: 24, search: value };

    if (value.length < 2) {
      fetchOption = {
        ...fetchOption,
        search: undefined
      };
    }

    searchDebounced(value);
    setPageSelector(fetchOption);
  };

  const handleAddToStore = (productId) => {
    const vendorId = info.store.storeId.toString();
    dispatch(
      addProductToVendor({
        vendorId,
        productId
      })
    );
  };

  const gridLayout = () => {
    document.querySelector('.product-wrapper-grid').classList.remove('list-view');
    const elems = document.querySelector('.gridRow').childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-3');
      el.classList.add('col-sm-6');
      el.classList.add('xl-4');
    });
  };

  const listLayout = () => {
    document.querySelector('.product-wrapper-grid').classList.add('list-view');
    const elems = document.querySelector('.gridRow').childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-12');
    });
  };

  const LayoutView = (layoutColumns) => {
    if (!document.querySelector('.product-wrapper-grid').classList.contains('list-view')) {
      const elems = document.querySelector('.gridRow').childNodes;
      [].forEach.call(elems, function (el) {
        el.className = '';
        el.classList.add('col-xl-' + layoutColumns);
      });
    }
  };

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.querySelector('.product-wrapper').classList.add('sidebaron');
    } else {
      setSidebaron(true);
      document.querySelector('.product-wrapper').classList.remove('sidebaron');
    }
  };

  const onOpenModal = (product) => {
    setOpen(true);
    setSingleProduct(product);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onClickDetailPage = () => {
    // const id = product.id;
    // history(`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${layout}/${id}`);
  };

  const PromoRibbons = (item) => (
    <>
      {item.status === 'sale' ? <span className="ribbon ribbon-danger">{item.status}</span> : ''}
      {item.status === '50%' ? <span className="ribbon ribbon-success ribbon-right">{item.status}</span> : ''}
      {item.status === 'gift' ? (
        <span className="ribbon ribbon-secondary ribbon-vertical-left">
          <i className="icon-gift">{item.status}</i>
        </span>
      ) : (
        ''
      )}
      {item.status === 'love' ? (
        <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
          <i className="icon-heart">{item.status}</i>
        </span>
      ) : (
        ''
      )}
      {item.status === 'Hot' ? <span className="ribbon ribbon ribbon-clip ribbon-warning">{item.status}</span> : ''}
    </>
  );

  const ShowPagination = () => {
    if (pageSelector.search?.length > 0 && isEmpty(products)) return null;

    const { currentPage, hasNextPage, hasPreviousPage, totalPages } = productOption;
    const left = currentPage - 2 < 2 ? 1 : currentPage - 2;
    const last = left + 5;
    const right = last > totalPages ? totalPages + 1 : last;

    return (
      <div className="d-flex justify-content-center mb-4">
        <Pagination aria-label="...">
          <ul className="pagination pagination-primary">
            <PaginationItem disabled={!hasPreviousPage} onClick={() => handlePagination(pageSelector.page - 1, pageSelector.size)}>
              <PaginationLink>{translate(Previous)}</PaginationLink>
            </PaginationItem>
            {range(left, right).map((index) => {
              return (
                <PaginationItem active={index === currentPage} key={index} onClick={() => handlePagination(index, pageSelector.size)}>
                  <PaginationLink>{index}</PaginationLink>
                </PaginationItem>
              );
            })}
            <PaginationItem disabled={!hasNextPage} onClick={() => handlePagination(currentPage + 1, pageSelector.size)}>
              <PaginationLink>{translate(Next)}</PaginationLink>
            </PaginationItem>
          </ul>
        </Pagination>
      </div>
    );
  };

  const ShowProductNumberSummary = ({ productOption }) => {
    if (!productOption) return null;

    const { currentPage, rowsPerPage, totalItems } = productOption;
    const countStart = (currentPage - 1) * rowsPerPage;
    const countEnd = currentPage * rowsPerPage;
    const from = countStart === 0 ? 1 : countStart;
    const to = countEnd > totalItems ? totalItems : countEnd;

    return (
      <Col md="5" sm="12" className="text-start mt-2">
        <span className="f-w-600 m-r-5">{`Showing products ${from} - ${to} of ${totalItems} results`}</span>
      </Col>
    );
  };

  return (
    <Fragment>
      <Breadcrumb parent="Marketplace" title="Products" />
      <Container fluid={true} className="product-wrapper">
        <div className="product-grid">
          <div className="feature-products">
            <Row>
              <Col md="3" sm="12" className="products-total">
                <div className="square-product-setting d-inline-block">
                  <a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript">
                    <Grid />
                  </a>
                </div>
                <div className="square-product-setting d-inline-block">
                  <a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript">
                    <List />
                  </a>
                </div>
                <span className="d-none-productlist filter-toggle" onClick={() => setFilterSidebar(!filterSidebar)}>
                  <h6 className="mb-0">
                    {Filters}
                    <span className="ms-2">
                      <i className="toggle-data fa fa-chevron-down"></i>
                    </span>
                  </h6>
                </span>
                <div className="grid-options d-inline-block">
                  <ListGroup as="ul">
                    <li>
                      <a className="product-2-layout-view" onClick={() => LayoutView(6)} href="#javascript">
                        <span className="line-grid line-grid-1 bg-primary"></span>
                        <span className="line-grid line-grid-2 bg-primary"></span>
                      </a>
                    </li>
                    <li>
                      <a className="product-3-layout-view" onClick={() => LayoutView(4)} href="#javascript">
                        <span className="line-grid line-grid-3 bg-primary"></span>
                        <span className="line-grid line-grid-4 bg-primary"></span>
                        <span className="line-grid line-grid-5 bg-primary"></span>
                      </a>
                    </li>
                    <li>
                      <a className="product-4-layout-view" onClick={() => LayoutView(3)} href="#javascript">
                        <span className="line-grid line-grid-6 bg-primary"></span>
                        <span className="line-grid line-grid-7 bg-primary"></span>
                        <span className="line-grid line-grid-8 bg-primary"></span>
                        <span className="line-grid line-grid-9 bg-primary"></span>
                      </a>
                    </li>
                    <li>
                      <a className="product-6-layout-view" onClick={() => LayoutView(2)} href="#javascript">
                        <span className="line-grid line-grid-10 bg-primary"></span>
                        <span className="line-grid line-grid-11 bg-primary"></span>
                        <span className="line-grid line-grid-12 bg-primary"></span>
                        <span className="line-grid line-grid-13 bg-primary"></span>
                        <span className="line-grid line-grid-14 bg-primary"></span>
                        <span className="line-grid line-grid-15 bg-primary"></span>
                      </a>
                    </li>
                  </ListGroup>
                </div>
              </Col>
              <ShowProductNumberSummary productOption={productOption} />
              <Col md="2" sm="12" className="text-end">
                <span className="f-w-600 m-r-5">{`Display `}</span>
                <div className="select2-drpdwn-product select-options d-inline-block">
                  <select className="form-control btn-square" name="select" onChange={(e) => handlePagination(pageSelector.page, e.target.value)} value={pageSelector.size ?? 24}>
                    <option value="24">{'24 per page'}</option>
                    <option value="48">{'48 per page'}</option>
                    <option value="100">{'100 per page'}</option>
                  </select>
                </div>
              </Col>
              <Col md="2" sm="12" className="text-end">
                <span className="f-w-600 m-r-5">{`Sort By `}</span>
                <div className="select2-drpdwn-product select-options d-inline-block">
                  <select className="form-control btn-square" name="select" onChange={(e) => handleSorting(e)} value={translate(currentSortValue)}>
                    <option value={Recommended} data-column="productId" data-value="desc">
                      {translate(Recommended)}
                    </option>
                    <option value={LowestPrices} data-column="price" data-value="asc">
                      {translate(LowestPrices)}
                    </option>
                    <option value={HighestPrices} data-column="price" data-value="desc">
                      {translate(HighestPrices)}
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="3" md="6">
                <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                  <div className="filter-section">
                    <Card>
                      <CardHeader>
                        <h6 className="mb-0 f-w-600">
                          {Filters}
                          <span className="pull-right">
                            <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                          </span>
                        </h6>
                      </CardHeader>
                      <div className="left-filter">
                        <CardBody className="filter-cards-view animate-chk">
                          <MarketplaceFilter pageSelector={pageSelector} setPageSelector={setPageSelector} />
                          {/* <div className="product-filter text-center mt-2">
                            <Media className="img-fluid banner-product m-auto" src={require('../../../assets/images/ecommerce/banner.jpg')} alt="" />
                          </div> */}
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col xl="9" md="6">
                <Form>
                  <div className="m-0 search-product">
                    <Input className="form-control" type="text" placeholder="search" defaultValue={pageSelector.search} onChange={(e) => handleSearch(e.target.value)} />
                    <i className="fa fa-search"></i>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>

          <div className="product-wrapper-grid">
            {pageSelector.search?.length > 2 && isEmpty(products) ? (
              <div className="search-not-found text-center">
                <div>
                  <img className="img-fluid second-search" src={errorImg} alt="" />
                  <p>{NotFoundData}</p>
                </div>
              </div>
            ) : (
              <Row className="gridRow">
                {products
                  ? products.map((item, i) => (
                      <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                        <Card>
                          <div className="product-box">
                            <div className="product-img">
                              <PromoRibbons />
                              <img className="img-fluid" src={item?.productImages[0]?.fileLink} alt="" />
                              <div className="product-hover">
                                <ul>
                                  {/* <li>
                                    <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}>
                                      <Button color="default" onClick={() => {}}>
                                        <i className="icon-shopping-cart"></i>
                                      </Button>
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Button color="default" data-toggle="modal" onClick={() => onOpenModal(item)}>
                                      <i className="icon-eye"></i>
                                    </Button>
                                  </li>
                                  {/* <li>
                                    <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
                                      <Button color="default" onClick={() => {}}>
                                        <i className="icon-heart"></i>
                                      </Button>
                                    </Link>
                                  </li> */}
                                </ul>
                                {item.productOwner.ownerId !== info.store?.storeId.toString() && (
                                  <Button color="primary" size="lg" onClick={() => handleAddToStore(item.productId)}>
                                    Add to Store
                                  </Button>
                                )}
                              </div>
                            </div>
                            <div className="product-details">
                              <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                              </div>
                              <h6 style={{ cursor: 'pointer' }} onClick={() => onClickDetailPage(item)} className="font-primary">
                                {item.productName}
                              </h6>
                              <p>{item.productDetails?.condition}</p>
                              <div className="product-price">
                                {transformProductPrice(item)}
                                <del>
                                  {symbol} {item.discountPrice}
                                </del>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))
                  : ''}

                <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                  <ModalBody>
                    <ModalHeader toggle={onCloseModal}>
                      <div className="product-box row">
                        <Col lg="6" className="product-img">
                          <Media className="img-fluid" src={singleProduct?.productImages ? singleProduct?.productImages[0]?.fileLink : ''} alt="" />
                        </Col>
                        <Col lg="6" className="product-details  text-start">
                          <h4>{singleProduct?.productName}</h4>
                          <div className="product-price">
                            {transformProductPrice(singleProduct)}
                            <del>
                              {symbol}
                              {singleProduct.discountPrice}
                            </del>
                          </div>
                          <div className="product-view">
                            <h6 className="f-w-600">{ProductDetails}</h6>
                            <p className="mb-0">{decodeHtmlEntity('span', singleProduct?.productDetails?.description.substring(0, 1000))}</p>
                          </div>
                          <div className="product-size">
                            <ul>
                              {ProductSizeArray.map((items, i) => (
                                <li key={i}>
                                  <Button color="outline-light">{items}</Button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="product-qnty">
                            <div className="addcart-btn">
                              <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart/`}>
                                <Button color="primary" className="me-2 mt-2" onClick={() => {}}>
                                  {AddToStore}
                                </Button>
                              </Link>
                              <Button onClick={() => onClickDetailPage(singleProduct)} color="primary" className="me-2 mt-2">
                                {ViewDetails}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </ModalHeader>
                  </ModalBody>
                </Modal>
              </Row>
            )}
          </div>
        </div>
        <ShowPagination />
      </Container>
    </Fragment>
  );
};

export default MarketPlace;
